import axios from 'axios';
import { getItemFromStore } from '../helpers/utils';

const API_URL = process.env.REACT_APP_API_URL;

export const searchAll = async (query) => {
  try {
    const token = getItemFromStore('token', null);
    const response = await axios.get(`${API_URL}/search`, {
      params: { query },
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Search error:', error);
    throw error;
  }
};

export const searchByType = async (query, type) => {
  try {
    const token = getItemFromStore('token', null);
    const response = await axios.get(`${API_URL}/search/${type}`, {
      params: { query },
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error(`${type} search error:`, error);
    throw error;
  }
}; 
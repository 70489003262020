import React, { Fragment, useEffect, useState } from 'react';
import { DropdownItem, DropdownMenu, Form, Input, Dropdown, DropdownToggle, Badge, Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Avatar from '../common/Avatar';
import { isIterableArray } from '../../helpers/utils';
import Flex from '../common/Flex';
import { searchAll, searchByType } from '../../services/searchService';
import debounce from 'lodash/debounce';

const SearchResultItem = ({ item, type }) => {
  const getItemDetails = () => {
    switch (type) {
      case 'campaigns':
        return {
          title: `Campaign: ${item.name}`,
          subtitle: `ID: ${item.campaignId}`,
          link: `/campaigns/${item.campaignId}`,
          icon: 'bullhorn'
        };
      case 'posts':
        return {
          title: `Post: ${item.title}`,
          subtitle: `ID: ${item.postId}`,
          link: `/posts/${item.postId}`,
          icon: 'file-alt'
        };
      case 'users':
        return {
          title: `User: ${item.name}`,
          subtitle: item.email,
          link: `/users/${item.userId}`,
          icon: 'user'
        };
      default:
        return {
          title: item.title || item.name,
          subtitle: item.subtitle,
          link: item.url,
          icon: 'search'
        };
    }
  };

  const { title, subtitle, link, icon } = getItemDetails();

  return (
    <DropdownItem className="px-card py-2" tag={Link} to={link}>
      <Media className="align-items-center">
        <div className="avatar avatar-xl mr-2">
          <div className="avatar-name rounded-circle">
            <FontAwesomeIcon icon={icon} />
          </div>
        </div>
        <Media body>
          <h6 className="mb-0">{title}</h6>
          <p className="fs--2 mb-0 text-500">{subtitle}</p>
        </Media>
      </Media>
    </DropdownItem>
  );
};

const SearchBox = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchType, setSearchType] = useState('all');
  const [searchResults, setSearchResults] = useState({
    campaigns: [],
    posts: [],
    users: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const performSearch = async (query, type) => {
    if (!query) {
      setSearchResults({ campaigns: [], posts: [], users: [] });
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      if (type === 'all') {
        const results = await searchAll(query);
        setSearchResults(results);
      } else {
        const results = await searchByType(query, type);
        setSearchResults(prev => ({
          ...prev,
          [type]: results
        }));
      }
    } catch (err) {
      setError('Search failed. Please try again.');
      console.error('Search error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = debounce(performSearch, 300);

  useEffect(() => {
    debouncedSearch(searchInputValue, searchType);
    return () => debouncedSearch.cancel();
  }, [searchInputValue, searchType]);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleSearchTypeChange = (type) => {
    setSearchType(type);
    performSearch(searchInputValue, type);
  };

  const hasResults = Object.values(searchResults).some(results => results.length > 0);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="search-box">
      <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={dropdownOpen}>
        <Form className="position-relative">
          <Input
            type="search"
            placeholder={`Search ${searchType === 'all' ? 'everything' : searchType}...`}
            aria-label="Search"
            className="rounded-pill search-input"
            value={searchInputValue}
            onChange={({ target }) => setSearchInputValue(target.value)}
          />
          <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
          {searchInputValue && (
            <button 
              className="close" 
              onClick={(e) => {
                e.preventDefault();
                setSearchInputValue('');
              }}
            >
              <FontAwesomeIcon icon="times" />
            </button>
          )}
        </Form>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-card" style={{ width: '25rem' }}>
        <div className="card-header">
          <nav className="nav nav-pills nav-pills-falcon">
            <div className="nav-item" onClick={() => handleSearchTypeChange('all')}>
              <span className={`nav-link cursor-pointer ${searchType === 'all' ? 'active' : ''}`}>
                All
              </span>
            </div>
            <div className="nav-item" onClick={() => handleSearchTypeChange('campaigns')}>
              <span className={`nav-link cursor-pointer ${searchType === 'campaigns' ? 'active' : ''}`}>
                Campaigns
              </span>
            </div>
            <div className="nav-item" onClick={() => handleSearchTypeChange('posts')}>
              <span className={`nav-link cursor-pointer ${searchType === 'posts' ? 'active' : ''}`}>
                Posts
              </span>
            </div>
            <div className="nav-item" onClick={() => handleSearchTypeChange('users')}>
              <span className={`nav-link cursor-pointer ${searchType === 'users' ? 'active' : ''}`}>
                Users
              </span>
            </div>
          </nav>
        </div>
        <div className="card-body p-0">
          <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
            {isLoading ? (
              <div className="text-center py-4">
                <FontAwesomeIcon icon="spinner" spin />
                <span className="ml-2">Searching...</span>
              </div>
            ) : error ? (
              <div className="text-center text-danger py-4">
                <FontAwesomeIcon icon="exclamation-circle" />
                <span className="ml-2">{error}</span>
              </div>
            ) : !hasResults && searchInputValue ? (
              <div className="text-center text-500 py-4">
                No results found
              </div>
            ) : (
              <>
                {(searchType === 'all' || searchType === 'campaigns') && searchResults.campaigns.length > 0 && (
                  <>
                    <DropdownItem header className="px-card py-2">
                      Campaigns
                    </DropdownItem>
                    {searchResults.campaigns.map((campaign, index) => (
                      <SearchResultItem key={campaign.campaignId || index} item={campaign} type="campaigns" />
                    ))}
                  </>
                )}

                {(searchType === 'all' || searchType === 'posts') && searchResults.posts.length > 0 && (
                  <>
                    <DropdownItem header className="px-card py-2">
                      Posts
                    </DropdownItem>
                    {searchResults.posts.map((post, index) => (
                      <SearchResultItem key={post.postId || index} item={post} type="posts" />
                    ))}
                  </>
                )}

                {(searchType === 'all' || searchType === 'users') && searchResults.users.length > 0 && (
                  <>
                    <DropdownItem header className="px-card py-2">
                      Users
                    </DropdownItem>
                    {searchResults.users.map((user, index) => (
                      <SearchResultItem key={user.userId || index} item={user} type="users" />
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default SearchBox;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NavbarDropdown from './NavbarDropdown';
import {
  authenticationRoutes,
  // chatRoutes,
  // ECommerceRoutes,
  // emailRoutes,
  homeRoutes,
  pageRoutes

  // widgetsRoutes,
  // kanbanRoutes,
  // calenderRoutes
} from '../../routes';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { breakpoints, getPageName } from '../../helpers/utils';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';
import SearchBox from './SearchBox';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed, setShowBurgerMenu }) => {
  const { isCombo, isTopNav } = useContext(AppContext);

  const pages = [pageRoutes];

  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    isTopNav && !isCombo && windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
    isCombo && windowWidth < breakpoints[navbarBreakPoint] && setShowBurgerMenu(false);
  };
  const isLanding = getPageName('landing');
  return (
    <>
      <NavItem>
        <NavLink className="nav-link" to="/posts">
          Posts
        </NavLink>
      </NavItem>

      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link" to="/users">
          Users
        </NavLink>
      </NavItem>

      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link" to="/campaigns">
          Campaigns
        </NavLink>
      </NavItem>

      <NavItem onClick={handleSetNavbarCollapsed}>
        <NavLink className="nav-link" to="/promoted">
          Promoted
        </NavLink>
      </NavItem>

 
    </>
  );
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;

import React from 'react';
import { Input, InputGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchInput = ({ placeholder, value, onChange, className = '' }) => {
  return (
    <InputGroup className={`search-input ${className}`}>
      <Input
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        className="form-control rounded-pill"
      />
      {/* <div className="position-absolute search-box-icon">
        <FontAwesomeIcon icon="search" className="text-400" />
      </div> */}
    </InputGroup>
  );
};

export default SearchInput; 